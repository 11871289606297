<template lang="pug">
canvas.canvas.fixed(ref='canvas')
</template>

<script>
import createREGL from 'regl';
import Controls from 'controls-state';
import Gui from 'controls-gui';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createCommand } from '@/commands/particle';
import { Meteor, getRandom } from '@/commands/particle/Meteor';

export default {
  name: 'ParticleShower',
  setup() {
    const canvas = ref(null);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * (devicePixelRatio > 2 ? devicePixelRatio : 2);
        canvas.value.height = window.innerHeight * (devicePixelRatio > 2 ? devicePixelRatio : 2);
      };
      window.addEventListener('resize', onResize);
      onResize();
      const count = 60;
      const draws = [];

      const state = Gui(
        Controls({
          size: Controls.Slider(1, { min: 0.5, max: 1.5, step: 0.1 }),
          opacity: Controls.Slider(1, { min: 0.5, max: 1, step: 0.01 }),
        }),
        {
          containerCSS: `position: fixed; top: 0; right: 0;`,
        },
      );

      console.log(state);

      regl = createREGL({ canvas: canvas.value });
      for (let i = -1; i <= 1; i += 2 / count) {
        const x1 = getRandom(0.5, 3);
        const y1 = 1.4 * x1 - i;
        const x2 = getRandom(-3, -0.8);
        const y2 = 1.4 * x2 - i;
        const vector = {
          initialPosition: {
            x: x1,
            y: y1,
          },
          endPosition: {
            x: x2,
            y: y2,
          },
        };
        const size = getRandom(0.6, 3);
        const speed = getRandom(0.3, 0.6) * Math.sqrt(size) * 0.9;
        const delay = getRandom(0, 10);
        const meteor = new Meteor(vector, {
          speed,
          size,
          delay,
        });
        draws.push(createCommand(regl, meteor));
      }

      const totalTime = 30;
      const update = ({ time }) => {
        if (time < totalTime) {
          draws.forEach((draw) => {
            draw({
              size: state.size,
              opacity: state.opacity,
            });
          });
        }
      };
      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  cursor pointer
  background #000

  img
    display none

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0
</style>
