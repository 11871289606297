import frag from './particle.frag';
import vert from './particle.vert';

export const generateAttributes = (particles) => {
  const initialPositions = [];
  const endPositions = [];
  const sizes = [];
  const speeds = [];
  const alphas = [];
  const colors = [];
  const delays = [];
  const fades = [];
  particles.forEach((particle) => {
    initialPositions.push(
      particle.initialPosition.x,
      particle.initialPosition.y,
      particle.initialPosition.z,
    );
    endPositions.push(particle.endPosition.x, particle.endPosition.y, particle.endPosition.z);
    sizes.push(particle.size);
    speeds.push(particle.speed);
    alphas.push(particle.alpha);
    colors.push(particle.color.r / 255, particle.color.g / 255, particle.color.b / 255);
    delays.push(particle.delay);
    fades.push(particle.fade ? 1 : 0);
  });
  return { initialPositions, endPositions, sizes, speeds, alphas, colors, delays, fades };
};

export const createCommand = (regl, meteor) => {
  const particles = meteor.generateParticles();
  // const infinite = meteor.infinite ? 1 : 0;
  const {
    initialPositions,
    endPositions,
    sizes,
    speeds,
    alphas,
    colors,
    delays,
    fades,
  } = generateAttributes(particles);

  const randoms = [];
  for (let i = 0; i < particles.length; i++) {
    randoms.push(Math.random());
  }

  return regl({
    vert,
    frag,
    attributes: {
      a_initialPosition: initialPositions,
      a_endPosition: endPositions,
      a_size: sizes,
      a_speed: speeds,
      a_alpha: alphas,
      a_color: colors,
      a_random: randoms,
      a_delay: delays,
      a_fade: fades,
    },
    uniforms: {
      u_time: ({ time }) => time,
      u_size: regl.prop('size'),
      u_opacity: regl.prop('opacity'),
    },
    count: particles.length,
    primitive: 'points',
    depth: { enable: false },
    blend: {
      enable: true,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'dst alpha',
        dstAlpha: 'dst alpha',
      },
    },
  });
};
