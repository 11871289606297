export class Particle {
  constructor({ size, speed, alpha, color, initialPosition, endPosition, delay, fade }) {
    this.size = size || 5;
    this.speed = speed || 1;
    this.alpha = alpha || 1;
    this.color = color || {
      r: 255,
      g: 255,
      b: 255,
    };
    this.initialPosition = initialPosition || {
      x: -1,
      y: -1,
      z: 0,
    };
    this.endPosition = endPosition || {
      x: 1,
      y: 1,
      z: 0,
    };
    this.delay = delay || 0;
    this.center = fade || false;
  }
}
